import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

import { vw } from "../utilities/helpers";
import SEO from "../utilities/seo";

import Bit from "../components/bit";
import Glow from "../components/glow";
import Parallax from "../components/parallax";
import Footer from "../components/footer";
import Text from "../components/text";
import Line from "../components/line";

const Wrap = styled.section`
	position: relative;
	margin-left: ${vw(3)};
	width: ${vw(17)};
	margin-top: ${vw(3.5)};

	h1 {
		padding-bottom: ${vw(1)};

		span:first-child {
			padding-bottom: ${vw(0.25)};
		}
	}

	p,
	ul {
		width: 50%;
		padding-bottom: ${vw(0.5)};
	}

	ul {
		list-style: disc inside;
	}

	li {
		padding-bottom: ${vw(0.125)};
		line-height: 1.25;
	}

	@media (max-width: 1023px) {
		width: 90%;
		margin-left: 5%;
	}

	@media (max-width: 767px) {
		width: 80%;
		margin: 0 auto;

		h1 {
			padding-bottom: 48px;
		}

		p {
			padding-bottom: ${vw(1)};
		}

		li {
			padding-bottom: ${vw(0.25)};
		}
	}
`;

export default ({ data }) => {
	const html =
		data.allPrismicPage.edges[0].node.data.body1[0].primary.section_content
			.html;

	return (
		<>
			<main>
				<SEO title="Onboard" />

				<section>
					<Glow royal top="-15%" left={vw(-4)} delay="0" />
					<Bit arrow top="25%" right="15%" />
					<Bit arrow top="45%" right="15%" />

					<Glow warm bottom="-50%" right={vw(-4)} delay="0.75" />
					<Bit ring top="55%" right="35%" />

					<Wrap>
						<h1>
							<Text xlarge>Data Privacy Policy</Text>
						</h1>
						<section dangerouslySetInnerHTML={{ __html: html }} />
						<Line />
					</Wrap>
				</section>
				<Parallax b />
			</main>
			<Footer />
		</>
	);
};

export const query = graphql`
	query PrivacyQuery {
		allPrismicPage(filter: { uid: { eq: "data-privacy-policy" } }) {
			edges {
				node {
					uid
					data {
						body1 {
							... on PrismicPageDataBody1Section {
								primary {
									section_content {
										html
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
